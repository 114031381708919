import React from 'react'
import Layout from '../components/layout'

import Footer from '../components/Footer'
import { graphql } from 'gatsby'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  var dateString = ''

  if (frontmatter.date) {
    dateString = `Project report of ${frontmatter.date}`
  }

  return (
    <Layout>
      <div className="blog-post-container">
        <div className="blog-post">
          <h2 style={{ textAlign: 'center' }}>{frontmatter.title}</h2>
          <h2 style={{ textAlign: 'center' }}>{dateString}</h2>
          <div style={{ marginLeft: '100px', marginRight: '100px' }}
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM YYYY")
        path
        title
      }
    }
  }
`
